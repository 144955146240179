export const billingData = [
  {
    id: 340,
    location_id: 25,
    booking_id: 63,
    facility_id: 12,
    completed: -1,
    user_id: null,
    note: "Test",
    date: "2024-04-11T00:00:00.000Z",
    active: 1,
    signature_url:
      "https://storage.googleapis.com/hufix-verwaltung/signatures/1/signature.png",
    completed_at: "2024-04-21T10:02:24.000Z",
    completion_note: "Das ist noch ein Test",
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 9,
    location_name: "Einatmen Ausatmen Yoga Studio",
    address: "Klagenfurter Straße 14",
    postal_code: "9220",
    city: "Velden am Wörthersee",
    area_name: "Villach Land",
    facility_name: "Cube Standard",
    customer_name: "JUST4ONLINE e.U.",
  },

  {
    id: 348,
    location_id: 33,
    booking_id: 64,
    facility_id: 12,
    completed: 0,
    user_id: null,
    note: null,
    date: "2024-12-01T23:00:00.000Z",
    active: 1,
    signature_url: null,
    completed_at: null,
    completion_note: null,
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 10,
    location_name: "test",
    address: "Kärntner Straße 3",
    postal_code: "9220",
    city: "Velden am Wörthersee",
    area_name: "Villach Stadt",
    facility_name: "Cube Standard",
    customer_name: "JUST4ONLINE e.U.",
  },

  {
    id: 391,
    location_id: 35,
    booking_id: 69,
    facility_id: 12,
    completed: 0,
    user_id: null,
    note: null,
    date: "2024-11-19T23:00:00.000Z",
    active: 1,
    signature_url: null,
    completed_at: null,
    completion_note: null,
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 9,
    location_name: "Tesvorhaben",
    address: "Villacher straße 73a",
    postal_code: "9220",
    city: "Velden am Wörthersee",
    area_name: "Villach Land",
    facility_name: "Cube Standard",
    customer_name: "JUST4ONLINE e.U.",
  },
  {
    id: 397,
    location_id: 36,
    booking_id: 70,
    facility_id: 12,
    completed: 0,
    user_id: null,
    type: "Abholung",
    note: null,
    date: "2024-11-19T23:00:00.000Z",
    active: 1,
    signature_url: null,
    completed_at: null,
    completion_note: null,
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 11,
    location_name: "chipsconnect",
    address: "Villacher Straße 3",
    postal_code: "9020",
    city: "Klagenfurt am Wörthersee",
    area_name: "Klagenfurt",
    facility_name: "Cube Standard",
    customer_name: "chipsconnect GmbH",
  },
  {
    id: 404,
    location_id: 37,
    booking_id: 73,
    facility_id: 12,
    completed: 0,
    user_id: null,

    note: null,
    date: "2024-12-03T23:00:00.000Z",
    active: 1,
    signature_url: null,
    completed_at: null,
    completion_note: null,
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 9,
    location_name: "Testvorhaben",
    address: "Kärntner Straße 7",
    postal_code: "9220",
    city: "Velden am Wörthersee",
    area_name: "Villach Land",
    facility_name: "Cube Standard",
    customer_name: "chipsconnect GmbH",
  },
  {
    id: 413,
    location_id: 39,
    booking_id: 74,
    facility_id: 12,
    completed: 0,
    user_id: null,

    note: null,
    date: "2024-11-26T23:00:00.000Z",
    active: 1,
    signature_url: null,
    completed_at: null,
    completion_note: null,
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 9,
    location_name: "Judth",
    address: "Villacher straße 73a",
    postal_code: "9220",
    city: "Velden am Wörthersee",
    area_name: "Villach Land",
    facility_name: "Cube Standard",
    customer_name: "JUST4ONLINE e.U.",
  },
  {
    id: 414,
    location_id: 34,
    booking_id: 75,
    facility_id: 12,
    completed: 0,
    user_id: null,
    type: "Lieferung",
    note: null,
    date: "2024-10-27T23:00:00.000Z",
    active: 1,
    signature_url: null,
    completed_at: null,
    completion_note: null,
    important: null,
    flatrate: null,
    user_name: null,
    area_id: 9,
    location_name: "Testvorhaben",
    address: "Villacher straße 73a",
    postal_code: "9220",
    city: "Velden am Wörthersee",
    area_name: "Villach Land",
    facility_name: "Cube Standard",
    customer_name: "JUST4ONLINE e.U.",
  },
]
