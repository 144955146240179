import React from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import { formatToCurrency } from "helpers/misc_helpers"

export const columns = [
  {
    dataField: "id",
    text: "Nummer",
    sort: true,
    align: "center",
    headerAlign: "center",
    headerStyle: { textAlign: "center" },
    formatter: col => <>{col}</>,
  },
  {
    dataField: "address",
    text: "Standort",
    sort: true,
    formatter: (_, element) => (
      <span>
        {element.location_name}
        <br />
        {element.address}
        <br />
        {element.postal_code} {element.city}
      </span>
    ),
  },
  {
    dataField: "address",
    text: "Rechnungsadresse",
    sort: true,
    formatter: (_, element) => (
      <span>
        <Link to={"/termine/" + element.id}>{element.customer_name}</Link>
        <br />
        {element.address}
        <br />
        {element.postal_code} {element.city}
      </span>
    ),
  },
  {
    dataField: "date",
    text: "Lieferdatum",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: (_, element) => (
      <>{moment.utc(element?.date).format("DD.MM.YYYY")}</>
    ),
  },
  {
    dataField: "date",
    text: "Abholdatum",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: (_, element) => (
      <>{moment.utc(element?.date).add(21, "days").format("DD.MM.YYYY")}</>
    ),
  },
  {
    dataField: "total",
    text: "Gesamt",
    sort: true,
    headerStyle: _ => ({
      textAlign: "center",
    }),
    align: () => "center",
    formatter: col => <>{formatToCurrency(col)}</>,
  },
  {
    dataField: "test",
    text: "",
    sort: false,
    formatter: (_, element) => (
      <span>
        <Link className="btn btn-primary" to={"/rechnung/" + element.id}>
          öffnen
        </Link>
      </span>
    ),
  },
]
