import React, { useState, useEffect } from "react"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Input,
  CardTitle,
  InputGroup,
  Button,
} from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { formatToCurrency } from "helpers/misc_helpers"

const productListvar = [
  {
    id: 1,
    name: "Tagessatz",
    description: "01.12.2024 - 31.12.2024",
    price: 3.5,
    quantity: 31,
    total: 108.5,
  },
  {
    id: 2,
    name: "Zusatzreinigung",
    description: "16.12.2024",
    price: 70,
    quantity: 1,
    total: 70,
  },
]
const EditDraft = () => {
  const [productList, setProductList] = useState(productListvar)
  const navigate = useNavigate()
  const [dic, setDic] = useState(0)
  const [tax, setTax] = useState(0)
  const [charge, setCharge] = useState(0)

  const assigned = (productList || [])?.map(item => item.total)
  let subTotal = 0
  for (let i = 0; i < assigned.length; i++) {
    subTotal += Math.round(assigned[i])
  }

  useEffect(() => {
    let dic = 0.15 * subTotal
    let tax = 0.125 * subTotal
    if (subTotal !== 0) {
      setCharge(65)
    } else {
      setCharge(0)
    }
    setDic(dic)
    setTax(tax)
  }, [subTotal])

  function removeCartItem(id) {
    var filtered = productList.filter(function (item) {
      return item.id !== id
    })

    setProductList(filtered)
  }

  function countUP(id, prev_quantity, price) {
    setProductList(
      (productList || [])?.map(count => {
        return count.id === id
          ? {
              ...count,
              quantity: prev_quantity + 1,
              total: (prev_quantity + 1) * price,
            }
          : count
      })
    )
  }

  function countDown(id, prev_quantity, price) {
    setProductList(
      (productList || [])?.map(count =>
        count.id === id && count.quantity > 0
          ? {
              ...count,
              quantity: prev_quantity - 1,
              total: (prev_quantity - 1) * price,
            }
          : count
      )
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Entwurf" breadcrumbItem="Verrechnung" />

          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table align-middle mb-0 table-nowrap">
                      <thead className="table-light">
                        <tr>
                          <th>Leistung</th>
                          <th className="text-center">Einzelpreis</th>
                          <th className="text-center">Menge</th>
                          <th colSpan="2">Gesamt</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(productList || [])?.map(product => (
                          <tr key={product.id}>
                            <td>
                              <h5 className="font-size-14 text-truncate">
                                <Link
                                  to={"/ecommerce-product-detail/" + product.id}
                                  className="text-dark"
                                >
                                  {product.name}
                                </Link>
                              </h5>
                              <p className="mb-0">
                                {" "}
                                Lieferdatum :
                                <span className="fw-medium">
                                  {" "}
                                  {product.description}{" "}
                                </span>
                              </p>
                            </td>
                            <td className="">
                              <div
                                style={{
                                  width: "120px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <Input
                                  type="text"
                                  value={product.price}
                                  name="demo_vertical"
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  width: "120px",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                              >
                                <InputGroup>
                                  <div className="input-group-prepend">
                                    <Button
                                      type="button"
                                      color="primary"
                                      onClick={() =>
                                        countDown(
                                          product.id,
                                          product.quantity,
                                          product.price
                                        )
                                      }
                                    >
                                      -
                                    </Button>
                                  </div>
                                  <Input
                                    type="text"
                                    value={product.quantity}
                                    name="demo_vertical"
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <Button
                                      type="button"
                                      color="primary"
                                      onClick={() =>
                                        countUP(
                                          product.id,
                                          product.quantity,
                                          product.price
                                        )
                                      }
                                    >
                                      +{" "}
                                    </Button>
                                  </div>
                                </InputGroup>
                              </div>
                            </td>
                            <td>{formatToCurrency(product.total)}</td>
                            <td>
                              <Link
                                to="#"
                                onClick={() => removeCartItem(product.id)}
                                className="action-icon text-danger"
                              >
                                <i className="mdi mdi-trash-can font-size-18" />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Row className="mt-4">
                    <Col sm="6">
                      <button
                        className="btn btn-secondary"
                        onClick={() => navigate(-1)}
                      >
                        <i className="mdi mdi-arrow-left me-1" /> zurück
                      </button>
                    </Col>
                    <Col sm="6">
                      <div className="text-sm-end mt-2 mt-sm-0">
                        <button
                          className="btn btn-success"
                          onClick={() => navigate(-1)}
                        >
                          <i className="bx bxs-save me-1" /> Speichern
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Rechnungsadresse</CardTitle>

                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <address>
                          <strong>Rechnungsadresse:</strong>
                          <br />
                          <React.Fragment>
                            <span>JUST4ONLINE e.U.</span>
                            <br />
                          </React.Fragment>
                          <React.Fragment>
                            <span>Kärntner Straße 7</span>
                            <br />
                          </React.Fragment>
                          <React.Fragment>
                            <span>9220 Velden am Wörthersee</span>
                            <br />
                          </React.Fragment>
                        </address>
                      </Col>
                      <Col sm="6" className="text-sm-end">
                        <address>
                          <strong>Lieferadresse:</strong>
                          <br />

                          <React.Fragment>
                            <span>JUST4ONLINE e.U.</span>
                            <br />
                          </React.Fragment>
                          <React.Fragment>
                            <span>Gemonaplatz 3</span>

                            <br />
                          </React.Fragment>
                          <React.Fragment>
                            <span>9220 Velden am Wörthersee</span>
                            <br />
                          </React.Fragment>
                        </address>
                      </Col>
                    </Row>
                  </CardBody>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Übersicht</CardTitle>

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>Zwischensumme :</td>
                          <td>{formatToCurrency(subTotal)}</td>
                        </tr>
                        <tr>
                          <td>Rabatt : </td>
                          <td>- {formatToCurrency(dic.toFixed(2))}</td>
                        </tr>
                        <tr>
                          <th>Gesamt :</th>
                          <th>
                            {formatToCurrency((subTotal - dic).toFixed(2)) ||
                              ""}
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditDraft
