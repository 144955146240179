import UserInfoModal from "components/Common/UserInfoModal"
import {
  CreateEntry,
  GetFacilitiesByName,
  UpdateEntry,
} from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import moment from "moment"
import React, { useEffect, useState } from "react"
import AsyncSelect from "react-select/async"
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const BookingModal = ({
  modal,
  onSuccess,
  onClose,
  location,
  selectedEntry,
}) => {
  const setLoading = useLoading()
  const [reqObj, setReqObj] = useState({})
  const [error, setError] = useState()

  const handleSave = async () => {
    setLoading(true)
    console.log(reqObj)
    const errorMsg = checkReqObj(reqObj)
    if (errorMsg) {
      setLoading(false)
      return setError(errorMsg)
    }

    if (selectedEntry) await UpdateEntry("/bookings/" + reqObj.id, reqObj)
    else await CreateEntry("/bookings/", reqObj)

    onSuccess()
  }

  const checkReqObj = () => {
    if (
      !reqObj.pickup_date ||
      !reqObj.delivery_date ||
      !reqObj.facility_id ||
      !reqObj.quantity ||
      !reqObj.maintenance_interval
    )
      return "Bitte alle Pflichtfelder befüllen!"

    if (
      isNaN(reqObj.flatrate) ||
      isNaN(reqObj.daily_rate) ||
      isNaN(reqObj.pickup_rate) ||
      isNaN(reqObj.delivery_rate)
    )
      return "Ungültiges Zahlenformat!"

    const pickupDate = moment(reqObj.pickup_date)
    const deliveryDate = moment(reqObj.delivery_date)
    if (deliveryDate.isAfter(pickupDate))
      return "Das Abholdatum darf nicht vor dem Lieferdatum liegen!"

    return null
  }

  useEffect(() => {
    if (!selectedEntry)
      setReqObj({
        location_id: location?.id,
        delivery_date: location?.delivery_date
          ? moment(location?.delivery_date).toDate()
          : null,
        pickup_date: location?.pickup_date
          ? moment(location?.pickup_date).toDate()
          : null,
        flatrate: 0,
        daily_rate: 0,
        pickup_rate: 0,
        delivery_rate: 0,
        is_pickup_fixed: false,
      })
    else setReqObj(selectedEntry)
  }, [selectedEntry, location])

  const loadFacilities = async inputValue => {
    const results = await GetFacilitiesByName(inputValue)

    return results.map(entry => ({
      value: entry.id,
      label: entry.name,
    }))
  }

  return (
    <Modal size="xl" isOpen={modal} toggle={onClose} centered>
      <ModalHeader toggle={onClose} tag="h4">
        {selectedEntry ? "Anlage bearbeiten" : "Anlage hinzufügen"}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            handleSave()
          }}
        >
          <Row>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Datum Lieferung:</Label>

                <input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.delivery_date
                      ? moment(reqObj.delivery_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      delivery_date: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-2">
                <Label className="form-label">Datum Abholung:</Label>

                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.pickup_date
                      ? moment(reqObj.pickup_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      pickup_date: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-2 d-flex align-items-center">
                <input
                  id="cbox_ispickupfixed"
                  type="checkbox"
                  className="ms-1 me-2"
                  checked={reqObj?.is_pickup_fixed ?? false}
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      is_pickup_fixed: e.target.checked,
                    })
                  }
                />
                <label
                  htmlFor="cbox_ispickupfixed"
                  className="mb-0"
                  style={{ cursor: "pointer" }}
                >
                  Datum Abholung ist fixiert
                </label>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-2">
                <Label className="form-label">Datum Abmeldung:</Label>

                <Input
                  type="date"
                  className="form-control"
                  value={
                    reqObj?.decommission_date
                      ? moment(reqObj.decommission_date).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      decommission_date: e.target.value,
                    })
                  }
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">WC-Anlage:</Label>
                <AsyncSelect
                  loadOptions={loadFacilities}
                  className="select2-selection w-100"
                  defaultValue={{
                    value: selectedEntry?.facility_id,
                    label: selectedEntry?.facility_name,
                  }}
                  onChange={selectedOption => {
                    setReqObj({
                      ...reqObj,
                      facility_id: selectedOption.value,
                    })
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Menge:</Label>
                <Input
                  type="number"
                  min="1"
                  value={reqObj?.quantity ?? ""}
                  onChange={e => {
                    const value = parseInt(e.target.value)
                    if (value > 0) {
                      setReqObj({ ...reqObj, quantity: value })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  Wartungsintervall in Tagen:
                </Label>
                <Input
                  type="number"
                  min="1"
                  value={reqObj?.maintenance_interval ?? ""}
                  onChange={e => {
                    const value = parseInt(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        maintenance_interval: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Zustellgebühr:</Label>
                <Input
                  type="number"
                  min="0.00"
                  step="0.01"
                  value={reqObj?.delivery_rate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        delivery_rate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-4">
                <Label className="form-label">Abholgebühr:</Label>
                <Input
                  type="number"
                  min="0.00"
                  step="0.01"
                  value={reqObj?.pickup_rate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        pickup_rate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Pauschale:</Label>
                <Input
                  type="number"
                  min="0.00"
                  step="0.01"
                  value={reqObj?.flatrate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        flatrate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">Tagessatz: </Label>
                <Input
                  type="number"
                  min="0.0"
                  step="0.01"
                  value={reqObj?.daily_rate ?? "0"}
                  onChange={e => {
                    const value = parseFloat(e.target.value)
                    if (value > 0) {
                      setReqObj({
                        ...reqObj,
                        daily_rate: value,
                      })
                    }
                  }}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-2">
                <Label className="form-label">
                  Tagessatz Ab: <small>(optional)</small>
                </Label>

                <input
                  type="date"
                  className="form-control"
                  disabled={moment(reqObj.daily_rate_start_date).isBefore(
                    moment()
                  )}
                  value={
                    reqObj?.daily_rate_start_date
                      ? moment(reqObj.daily_rate_start_date).format(
                          "YYYY-MM-DD"
                        )
                      : ""
                  }
                  onChange={e =>
                    setReqObj({
                      ...reqObj,
                      daily_rate_start_date: e.target.value,
                    })
                  }
                />
                <small className="ms-2">
                  Kein Datum, bedeutet ab Aufstellung{" "}
                </small>
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <Label className="form-label">
                  Notiz: <small>(optional)</small>
                </Label>
                <Input
                  type="text"
                  value={reqObj?.note ?? ""}
                  onChange={e => setReqObj({ ...reqObj, note: e.target.value })}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  Speichern
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <UserInfoModal
        show={error != undefined}
        message={error}
        onConfirm={_ => setError()}
      />
    </Modal>
  )
}

export default BookingModal
