import React, { useState } from "react"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
const { SearchBar } = Search
import { Col, Row, Card, CardBody, Button } from "reactstrap"
import { GetData } from "helpers/backend_helper"
import { useLoading } from "helpers/custom_hooks"
import "flatpickr/dist/themes/material_blue.css"
import { columns } from "./columns"
import { billingData } from "./appointments"

function LocationList() {
  const setLoading = useLoading()
  const [data, setData] = useState(billingData)
  const [selectedRows, setSelectedRows] = useState([])

  const pageOptions = {
    sizePerPage: 20,
    totalSize: data.length,
    custom: true,
  }

  const loadData = async () => {
    setLoading(true)

    const data = await GetData(`/appointments`)
    setData(data)

    setLoading(false)
  }

  // Konfiguration für auswählbare Zeilen
  const selectRow = {
    mode: "checkbox", // Zeilen auswählbar per Checkbox
    clickToSelect: true, // Klick auf die Zeile aktiviert die Checkbox
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedRows([...selectedRows, row.id]) // Zeile hinzufügen
      } else {
        setSelectedRows(selectedRows.filter(id => id !== row.id)) // Zeile entfernen
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedRows(rows.map(row => row.id)) // Alle Zeilen hinzufügen
      } else {
        setSelectedRows([]) // Auswahl löschen
      }
    },
  }

  const handleProcessSelected = () => {
    console.log("Ausgewählte IDs:", selectedRows)
    // Hier kannst du die Logik für die Verarbeitung der ausgewählten Einträge hinzufügen
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions || [])}
            keyField="id"
            data={data || []}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="id"
                data={data}
                remote={{ filter: true }}
                columns={columns || []}
                bootstrap4
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row>
                      <Col>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          placeholder="Suche..."
                        />
                      </Col>
                      <Col className="text-end">
                        <Button
                          color="primary"
                          onClick={handleProcessSelected}
                          disabled={selectedRows.length === 0}
                        >
                          Verrechnen ({selectedRows.length})
                        </Button>
                      </Col>
                    </Row>

                    <div className="table-responsive">
                      <BootstrapTable
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        bordered={false}
                        noDataIndication={"Es sind keine Termine vorhanden!"}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                        selectRow={selectRow} // Hinzufügen der selectRow-Option
                      />
                    </div>
                    <div className="pagination pagination-rounded justify-content-end mb-2">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default LocationList
