import React from "react"
import { Link, useNavigate } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import { map } from "lodash"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { formatToCurrency } from "helpers/misc_helpers"

const InvoiceDetail = props => {
  const navigate = useNavigate()
  const printInvoice = () => {
    window.print()
  }

  const invoiceDetail = {
    id: "2024-351",
    subTotal: 178.5,
    discount: 28.5,
    total: 150,
    items: [
      {
        id: 1,
        name: "Tagessatz",
        description: "01.12.2024 - 31.12.2024",
        price: 3.5,
        quantity: 31,
        total: 108.5,
      },
      {
        id: 2,
        name: "Zusatzreinigung",
        description: "16.12.2024",
        price: 70,
        quantity: 1,
        total: 70,
      },
    ],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Rechnungen" breadcrumbItem="Details" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="invoice-title">
                    <h4 className="float-end font-size-16">
                      Auftrag # {invoiceDetail?.id}
                    </h4>
                  </div>
                  <hr />
                  <Row>
                    <Col sm="6">
                      <address>
                        <strong>Rechnungsadresse:</strong>
                        <br />
                        <React.Fragment>
                          <span>JUST4ONLINE e.U.</span>
                          <br />
                        </React.Fragment>
                        <React.Fragment>
                          <span>Kärntner Straße 7</span>
                          <br />
                        </React.Fragment>
                        <React.Fragment>
                          <span>9220 Velden am Wörthersee</span>
                          <br />
                        </React.Fragment>
                      </address>
                    </Col>
                    <Col sm="6" className="text-sm-end">
                      <address>
                        <strong>Lieferadresse:</strong>
                        <br />

                        <React.Fragment>
                          <span>JUST4ONLINE e.U.</span>
                          <br />
                        </React.Fragment>
                        <React.Fragment>
                          <span>Gemonaplatz 3</span>

                          <br />
                        </React.Fragment>
                        <React.Fragment>
                          <span>9220 Velden am Wörthersee</span>
                          <br />
                        </React.Fragment>
                      </address>
                    </Col>
                  </Row>
                  <div className="py-2 mt-3"></div>
                  <div className="table-responsive">
                    <Table className="table-nowrap">
                      <thead>
                        <tr>
                          <th style={{ width: "70px" }}>Nr.</th>
                          <th>Leistung</th>
                          <th className="text-end">Menge</th>
                          <th className="text-end">Einzelpreis</th>
                          <th className="text-end">Gesamt</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(invoiceDetail?.items, (item, key) => (
                          <tr key={key}>
                            <td>{item.id}</td>
                            <td>
                              <p className="font-size-14 mb-1 pb-0">
                                {item.name}
                              </p>
                              <span className="text-muted d-block">
                                {item.description}
                              </span>
                            </td>
                            <td className="text-end">{item.quantity}</td>
                            <td className="text-end">
                              {formatToCurrency(item.price)}
                            </td>
                            <td className="text-end">
                              {formatToCurrency(item.total)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="2"></td>
                          <td colSpan="2" className="text-end">
                            Summe
                          </td>
                          <td className="text-end">
                            {formatToCurrency(invoiceDetail?.subTotal)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>

                          <td colSpan="2" className="border-0 text-end">
                            <strong>Rabatt</strong>
                          </td>
                          <td className="border-0 text-end">
                            - {formatToCurrency(invoiceDetail?.discount)}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2"></td>

                          <td colSpan="2" className="border-0 text-end">
                            <strong>Gesamt</strong>
                          </td>
                          <td className="border-0 text-end">
                            <h4 className="m-0">
                              {formatToCurrency(invoiceDetail?.total)}
                            </h4>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-print-none">
                    <div className="float-end">
                      <Link
                        to="#"
                        onClick={printInvoice}
                        className="btn btn-success me-2"
                      >
                        <i className="fa fa-print" />
                      </Link>
                      <button
                        className="btn btn-secondary"
                        onClick={() => navigate(-1)}
                      >
                        zurück
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default InvoiceDetail
