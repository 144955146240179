import React, { useState, useEffect } from "react"
import {
  Card,
  CardBody,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classnames from "classnames"
import { useLocation, useNavigate } from "react-router-dom"
import LocationList from "./locationlist"
import DraftList from "./draftlist"
import InvoicesList from "./invoices"
import moment from "moment"
import "moment/locale/de" // Deutsche Lokalisierung
moment.locale("de")

const Billing = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // Hole den aktiven Tab aus der URL oder setze Standardwert "1"
  const searchParams = new URLSearchParams(location.search)
  const defaultTab = searchParams.get("tab") || "1"
  const [activeTab, setActiveTab] = useState(defaultTab)

  // Monatsauswahl
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedMonth, setSelectedMonth] = useState(moment().startOf("month")) // Aktueller Monat
  const months = Array.from({ length: 12 }, (_, i) =>
    moment().subtract(i, "months").startOf("month")
  ).reverse() // Letzte 12 Monate, beginnend mit dem aktuellen Monat

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      navigate(`?tab=${tab}`, { replace: true }) // Setze URL-Parameter
    }
  }

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen)

  const handleMonthSelect = month => {
    setSelectedMonth(month)
    console.log("Gewählter Monat:", month.format("MMMM YYYY")) // Für Debugging oder Weiterverarbeitung
  }

  // Synchronisiere den Zustand mit der URL, wenn sich die URL ändert
  useEffect(() => {
    const newTab = searchParams.get("tab") || "1"
    if (newTab !== activeTab) {
      setActiveTab(newTab)
    }
  }, [location.search])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Übersicht" breadcrumbItem="Verrechnung" />
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <Nav tabs className="nav-tabs-custom nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => toggleTab("1")}
                    >
                      <span className="d-none d-sm-block">Offen</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => toggleTab("2")}
                    >
                      <span className="d-none d-sm-block">Entwurf</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => toggleTab("3")}
                    >
                      <span className="d-none d-sm-block">Erledigt</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                  <DropdownToggle caret>
                    {selectedMonth.format("MMMM YYYY")}
                  </DropdownToggle>
                  <DropdownMenu>
                    {months.map((month, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => handleMonthSelect(month)}
                      >
                        {month.format("MMMM YYYY")}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <TabContent activeTab={activeTab} className="p-3 text-muted">
                <TabPane tabId="1">
                  <LocationList selectedMonth={selectedMonth} />
                </TabPane>
                <TabPane tabId="2">
                  <DraftList selectedMonth={selectedMonth} />
                </TabPane>
                <TabPane tabId="3">
                  <InvoicesList selectedMonth={selectedMonth} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Billing
